import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <nav className="absolute w-full sm:px-16 ">
      <ul className="relative w-full flex items-center justify-between p-4 sm:p-2">
        <li className="hidden">
          <div className="p-3 m-3 rounded shadow-lg bg-gradient-to-l from-green-400 md:from-blue-700 to-blue-500">
            <Link to="/">
              <a className="no-underline text-accent-1 font-bold flex flex-col">
                <span className="text-white text-2xl sm:text-3xl">
                  Geografia Irada
                </span>
                <span className="text-gray-100 italic text-sm md:text-x1">
                  Qualidade com criatividade
                </span>
              </a>
            </Link>
          </div>
        </li>
        <ul className="mt-6 lg:mt-8 flex items-center justify-end  w-full space-x-4">
          {/* <li>
            <Link to="/cadastro">
              <button className="transition px-8 md:px-7 py-2 text-sm md:text-base bg-gradient-to-l from-green-400 md:from-green-700 to-green-500 rounded-md text-white font-bold cursor-pointer border-b-4 border-green-900 transform hover:translate-y-1 hover:border-opacity-0 hover:from-green-400 hover:to-green-300 sm:hover:from-green-500 sm:hover:to-green-300">
                Cadastre-se
              </button>
            </Link>
          </li> */}
          <li>
            <Link to="/plataforma/aulas">
              <button className="transition px-2 md:px-7 py-2 text-sm md:text-base bg-gradient-to-l from-green-400 md:from-blue-700 to-blue-500 rounded-md text-white font-bold cursor-pointer border-b-4 border-blue-900 transform hover:translate-y-1 hover:border-opacity-0 hover:from-green-400 hover:to-blue-400 sm:hover:from-blue-600 sm:hover:to-blue-300">
                Acessar Plataforma
              </button>
            </Link>
          </li>
        </ul>
      </ul>
    </nav>
  );
}
