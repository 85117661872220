import React from "react";
import { BiFile } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import Accordion from "../../../../components/Accordion";
import Header from "../../../../components/Header";

export default function Conteudo(props) {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const contentData = props.location.state.content;

  function returnAulas() {
    history.goBack();
  }

  function StrongPhrase() {
    let text = contentData.descricao.split(" ");
    let firstText = text.splice(0, 3);
    let all = text.join(" ");
    firstText = firstText.join(" ");
    return (
      <p style={{ lineHeight: 1.5 }}>
        <h1 className="text-gray-800 font-semibold">{firstText}</h1> {all}
      </p>
    );
  }

  function hasMusic() {
    if (contentData.parodias) {
      return (
        <div>
          {contentData.parodias.map((music) => (
            <Accordion title="Paródia" videoID={music.link}></Accordion>
          ))}
        </div>
      );
    }
  }

  function hasVideo() {
    if (contentData.exercicioVideo) {
      return (
        <Accordion
          title="Exercícios com Vídeo"
          videoID={contentData.exercicioVideo.linkVideo}
          fileUrl={contentData.exercicioVideo.linkDownload}
          isVideoExercicio
        ></Accordion>
      );
    }
  }
  console.log(contentData);
  function hasFile() {
    if (contentData.linkDownloadMaterial) {
      return (
        <div className="mt-3 text-center">
          <div>
            <h1 className="uppercase text-lg text-green-900 font-semibold">
              Materiais
            </h1>
          </div>
          <a
            href={contentData.linkDownloadMaterial}
            target="_blank"
            className="mt-2 mb-5 transition mx-5 flex items-center justify-center font-semibold py-2 text-sm md:text-base cursor-pointer border-b-4 border-blue-900 transform hover:translate-y-1 hover:border-opacity-0  bg-blue-800 hover:bg-blue-600 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <BiFile className="m-0" />
            <span className="ml-2">Baixar Arquivo</span>
          </a>
        </div>
      );
    }
  }
  console.log(contentData);
  return (
    <div>
      <Header title="Aulas e Conteúdos" showBackButton />

      <section className="p-4 lg:p-8 ">
        <div className="flex flex-col lg:flex-row w-full">
          <div className="flex-1 mb-4 lg:mb-0 lg:mr-4 ">
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                style={{
                  border: "none",
                  position: "absolute",
                  top: 0,
                  height: "100%",
                  width: "100%",
                }}
                src={"https://www.youtube.com/embed/" + contentData.linkVideo}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="flex flex-col bg-gray-100 rounded-md shadow-sm p-8 ">
            <h2 className="text-3xl text-gray-900 font-bold">
              {contentData.nome}
            </h2>
            <h1 className="text-2xl text-gray-600 font-semibold">
              {" "}
              {contentData.materia.nome}
            </h1>
            <p className="text-xl text-gray-700">Prof. Marcelo</p>
            {hasFile()}
          </div>
        </div>

        <div className="my-4 p-4 bg-gray-100 shadow-md rounded-md text-lg text-gray-700">
          {StrongPhrase()}
        </div>

        <div className="extras">
          {hasMusic()}
          {hasVideo()}
        </div>
      </section>
    </div>
  );
}
