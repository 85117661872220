import { Route, Switch, useRouteMatch } from "react-router-dom";
import Loader from "../../components/Loader";
import { useAuth } from "../../context/AuthProvider";
import DrawerContextProvider from "../../context/DrawerContext";
import Pricing, { PricingCard } from "../Front/components/Pricing";
import { BuyPlanPage } from "./components/BuyPlanPage";
import Navbar from "./components/NavBar";
import Apostila from "./pages/Apostilas";
import Aulas from "./pages/Aulas";
import Conteudo from "./pages/Conteudo";
import ExerciciosExtras from "./pages/ExerciciosExtras";
import ExerciciosVideos from "./pages/ExerciciosVideo";
import ExerciciosVideosPage from "./pages/ExerciciosVideo/exercicioVideoPage";
import NaReal from "./pages/NaReal";
import NaRealPage from "./pages/NaReal/naRealPage";
import Pilulas from "./pages/Pilulas";
import PilulaPage from "./pages/Pilulas/pilulaPage";
import PlanoDeEstudos from "./pages/PlanoDeEstudos";
import Simulados from "./pages/Simulados";
import Parodias from "./Parodias";
import ParodiasPage from "./Parodias/parodiasPage";

export default function Platform(props) {
  const match = useRouteMatch();

  return (
    <div className="flex min-h-screen bg-gray-200">
      <DrawerContextProvider>
        <Navbar {...props} />
        <section className="flex-1 h-screen overflow-auto">
          <Switch>
            <Route
              path={`${match.path}/planodeestudos`}
              component={PlanoDeEstudos}
            ></Route>
            <Route path={`${match.path}/aulas`} component={Aulas}></Route>
            <Route path={`${match.path}/conteudo`} component={Conteudo}></Route>
            <Route
              path={`${match.path}/apostilas`}
              exact
              component={Apostila}
            ></Route>
            <Route
              path={`${match.path}/pilulas`}
              exact
              component={Pilulas}
            ></Route>
            <Route
              path={`${match.path}/pilulas/description`}
              exact
              component={PilulaPage}
            ></Route>
            <Route
              path={`${match.path}/naReal`}
              exact
              component={NaReal}
            ></Route>
            <Route
              path={`${match.path}/naReal/description`}
              exact
              component={NaRealPage}
            ></Route>
            <Route
              path={`${match.path}/simulados`}
              exact
              component={Simulados}
            ></Route>
            <Route
              path={`${match.path}/exerciciosExtras`}
              exact
              component={ExerciciosExtras}
            ></Route>
            <Route
              path={`${match.path}/exerciciosVideos`}
              exact
              component={ExerciciosVideos}
            ></Route>
            <Route
              path={`${match.path}/exerciciosVideos/description`}
              exact
              component={ExerciciosVideosPage}
            ></Route>
            <Route
              path={`${match.path}/parodias`}
              exact
              component={Parodias}
            ></Route>
            <Route
              path={`${match.path}/parodias/description`}
              exact
              component={ParodiasPage}
            ></Route>
          </Switch>
        </section>
      </DrawerContextProvider>
    </div>
  );
}
